<template>
    <div class="couponPage">
        <div @click="$router.go(-1)" class="leftArrow">
            <svg-icon iconClass="leftArrow"></svg-icon>
        </div>
        <van-tabs swipeable animated>
            <van-tab title="已获得">
                <div class="tab_content">
                    <obtained/>
                </div>
            </van-tab>
            <van-tab title="已使用">
                <div class="tab_content">
                    <used />
                </div>
            </van-tab>
            <van-tab title="已过期">
                <div class="tab_content">
                    <expired />
                </div>
            </van-tab>

        </van-tabs>
    </div>
</template>

<script>
    import obtained from '@/views/mine/coupon/obtained';
    import used from '@/views/mine/coupon/used';
    import expired from '@/views/mine/coupon/expired';
    export default {
        components: {
            obtained,
            used,
            expired,
        },
        data() {
            return {
                
            }
        }
    }
</script>

<style lang='scss' scoped>
    .couponPage {
        position: relative;

        .leftArrow {
            position: absolute;
            left: 16px;
            top: 22px;
            transform: translate(0, -50%);
            font-size: 15px;
            color: $white1;
            z-index: 3;
        }

        /deep/ .van-tabs__wrap {
            background: $vermillion;
        }

        /deep/ .van-tabs__nav {
            width: 280px;
            margin-left: 50px;
            background: $vermillion;
        }

        /deep/ .van-tab__text {
            font-size: 15px;
            color: $white1;
        }

        /deep/ .van-tabs__line {
            background: $white1;
            bottom: 20px;
            width: 26px;
            // width: 50%;
        }

        /deep/ .van-tab--active {
            .van-tab__text {
                font-weight: 600;
                font-size: 18px;
            }

        }

        .tab_content {
            height: 100%;
            // padding: 0 16px;
            // overflow: auto;
            // -ms-overflow-style: none;
            // height: calc(100vh - 44px);
            // background: $whiteThree;
        }
    }
</style>