<template>
    <div class="obtainedList">
        <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
            :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
            <div class="couponList" v-for="(item, index) in gainedList" :key="index">
                <div class="couponTitle">{{item.goodsName}}</div>
                <div class="couponDesc">{{item.goodsDesc}}</div>
                <div class="couponBottom">
                    <p>有效期: {{item.expiredTime | time}}</p>
                    <div class="useBtn" @click="goUse(item.goodsType)"><span>去使用</span>
                        <svg-icon iconClass="rightArrow" class="rightArrow"></svg-icon>
                    </div>
                </div>
            </div>
        </Pullrefresh>
    </div>

</template>

<script>
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    import { backpack } from "@/api/mine/coupon.js";
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                gainedList: [],
                limit: 10,
                page: 1,
                // status: 2,
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.limit++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.page = 1;
                this.gainedList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    limit: this.limit,
                    page: this.page,
                    status: 2,
                }
                let ret = await this.$Api(backpack, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data;
                    this.gainedList = this.gainedList.concat(list);
                    // if (this.gainedList.length > 0) {
                    //     this.author = this.mediaInfos[0].author;
                    // }

                    if (list.length == 0 && this.page == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length < this.limit) {
                        this.finished = true;
                    }
                }
            },
            // 去使用
            goUse(type) {
                if (type == 1) {
                    this.$router.push('/loufeng');
                } else if (type == 3) {
                    this.$router.push('/onDemand/recommend');
                } else {
                    this.$router.push('/vipList');
                }
                
            }
        }
    }
</script>

<style lang='scss' scoped>
    .obtainedList {
        height: calc(100vh - 44px);

        .couponList {
            color: $white1;
            height: 120px;
            background: url('../../../../assets/png/vip_coupon.png');
            background-size: 100% 100%;
            margin: 15px 24px 0;
            padding: 15px 76px 13px 18px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .couponTitle {
                font-size: 16px;
                font-weight: 600;
            }

            .couponDesc {
                font-size: 11px;
                color: $whiteThree;
                flex: 2;
                margin: 9px 0;
                // height: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 16px;
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp: 2;
                // white-space: nowrap;
            }

            .couponUseTimer {
                font-size: 11px;
            }

            .couponBottom {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                font-size: 11px;

                .useBtn {
                    align-items: center;
                    width: 65px;
                    height: 22px;
                    line-height: 22px;
                    text-align: center;
                    border-radius: 15px;
                    background: $white1;
                    color: $lightMagenta;

                    .rightArrow {
                        margin-left: 2px;
                    }
                }
            }
        }
    }
</style>